@import "@/styles/common.scss";
.liveVideoPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $backColor;
  height: 100%;
  :global {
    .anticon {
      font-size: 56px;
    }
  }
}

.liveVideo {
  display: flex;
  flex-direction: column;
  height: 100%;
  :global {
    .ant-spin-blur {
      opacity: 0.3;
    }
  }
  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    background: $backColor;
    padding: 0 8px;
    :global {
      .anticon {
        cursor: pointer;
        margin-left: 4px;
      }
    }
  }
  .video {
    height: 100%;
    left: 0;
    outline: none;
    position: absolute;
    top: 0;
    width: 100%;
    object-fit: fill;
  }
}

@import "@/styles/common.scss";
.atShowBoxTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}
.atShowBox {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  .chatBox {
    background: $backColor;
    padding: 6px;
    height: 100%;
    overflow-y: auto;
    .chatItem {
      display: flex;
      margin-bottom: 12px;
      .chat {
        background-color: #87ceeb;
        padding: 6px;
        border-radius: 4px;
        display: inline-block;
        font-weight: 500;
        margin: 0 8px;
        word-break: break-all;
      }
      :global {
        .ant-avatar {
          flex-shrink: 0;
        }
      }
      &:nth-child(odd) {
        justify-content: end;
      }
    }
  }
  .commandBox {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    .tableAtName {
      color: $primaryColor;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }
}
.atSendForm {
  margin-top: 24px;
  .buttomFormItem {
    padding-left: 68px;
    margin-bottom: 0px;
    .formContent {
      display: flex;
      align-items: center;
      :global {
        .ant-btn-primary {
          margin-left: 8px;
        }
      }
      .tip {
        color: #ff4d4f;
        margin-left: 4px;
        font-size: 14px;
      }
    }
  }
}

.guestCarWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  :global {
    .ant-card-body {
      padding: 8px;
    }
    .ant-btn-primary {
      margin: 0 8px;
    }
  }
}
.carDiagnose {
  min-height: 100%;
  .searchBox {
    :global {
      .ant-btn:not(:first-child) {
        margin-left: 12px;
      }
    }
  }
  .carInfoBox {
    padding: 12px;
    background: #eee;
    :global {
      .ant-divider-inner-text {
        font-size: 12px;
      }
      .ant-divider-horizontal {
        margin: 2px 0px;
      }
    }
    .carInfoTitle {
      font-size: 12px;
      color: #999;
    }
    .carInfoContent {
      font-weight: 500;
      :global {
        .ant-btn-link {
          padding: 0;
        }
      }
      display: flex;
      .carInfoPerson{
        line-height: 35px;
        padding-right: 10px;
      }
      .carInfoDriver{
        line-height: 35px;
        padding-right: 10px;
      }
      .personPhone {
        font-size: 20px;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        line-height: 35px;
        border: black solid 1px;
        z-index: 99;
      }
      .driverPhone{
        font-size: 20px;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        line-height: 35px;
        border: black solid 1px;
        z-index: 99;
      }
    }
  }
  .carMap {
    height: 100%;
  }
}
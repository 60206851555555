@import "@/styles/common.scss";
.pageContentBox {
  min-height: 100%;
}

.fixPageContentBox {
  height: 100%;
  overflow: auto;
  :global {
    .ant-card-body {
      overflow-y: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.fixPageTabContentBox {
  height: 100%;
  overflow: auto;
  :global {
    .ant-card-body {
      overflow-y: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .ant-tabs-content-holder {
      background: #fff !important;
      .ant-tabs-tabpane {
        padding: 0 !important;
        overflow-x: hidden !important;
      }
    }
  }
}

// modal内使用tab展示commonTable时tab的样式类
.fixModalTab {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  :global {
    .ant-tabs-nav {
      margin-bottom: 0;
    }
    .ant-tabs-content-holder {
      background: #fff;
      flex-grow: 1;
      .ant-tabs-content {
        height: 100%;
      }
      .ant-tabs-tabpane {
        overflow-y: auto;
        padding: 8px;
        height: 100%;
      }
    }
  }
}

.searchBox {
  :global {
    .ant-btn:not(:first-child) {
      margin-left: $buttonSpacing;
    }
  }
}

.operateBox {
  margin-bottom: 12px;
  :global {
    .ant-btn:not(:first-child) {
      margin-left: $buttonSpacing;
    }
  }
}

.tableTagColumn {
  display: flex;
  flex-wrap: wrap;
  :global {
    .ant-tag {
      margin-top: 2px;
    }
  }
}

.operateTextButton {
  :global {
    .ant-btn {
      padding: 0;
      border: 0;
      height: auto;
      padding-left: $textButtonSpacing;
    }
  }
}

.tableOperateColumn {
  @extend .operateTextButton;
  margin-left: -4px;
}

.scrollMoreTableFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .desc {
    position: absolute;
    top: 6px;
    left: 0;
  }
}

.spaceBetweenFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 576px) {
  .searchBox {
    margin-top: 32px;
  }
  .scrollMoreTableFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .desc {
      position: relative;
    }
  }
}

.spaceBetweenPageContentBox {
  height: 100%;
  display: flex;
  justify-content: space-between;
  .carTree {
    flex-shrink: 0;
    width: 250px;
    background-color: #fff;
  }
  .contentBox {
    flex: auto;
    min-width: 0;
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    padding: 24px 12px;
    background-color: #fff;
    .searchButtonBox {
      margin-left: 16.7%;
      text-align: center;
      :global {
        .ant-btn-default {
          margin-left: 4px;
        }
      }
    }
    .tipBox {
      border-top: 1px solid $backColor;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .tableBox {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }
  }
}

.drawerTableBox {
  :global {
    .ant-drawer-body {
      display: flex;
      flex-direction: column;
    }
  }
}

// commonTable的外层容器样式类
.commonTableWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tags {
  margin-top: 8px;
  :global {
    .ant-tag {
      margin-bottom: 8px;
    }
  }
}
.commentTitle {
  display: flex;
  & > div {
    margin-right: 8px;
    color: #999;
  }
  :global {
    .ant-rate {
      margin-top: -7px;
    }
  }
}
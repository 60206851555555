.ultrasonicLocationBox {
  width: 100%;
  background-color: #fff;
  display: flex;
  .leftBox {
    position: relative;
    z-index: 1;
    right: -10px;
    .selectItemOne {
      top: 200px;
    }
    .selectItemTwo {
      top: 250px;
    }
    .selectItemThree {
      top: 315px;
    }
    .selectItemFour {
      top: 416px;
    }
    .selectItemFive {
      top: 534px;
    }
    .selectItemSix {
      top: 600px;
    }
  }
  .middleBox {
    position: relative;
    .middleImage {
      height: 800px;
      width: 477px;
      img {
        margin: 100px;
        height: 600px;
      }
    }
    .middleTop {
      display: flex;
      .selectItemOne {
        left: 84px;
        top: 82px;
        .line {
          height: 40px;
        }
      }
      .selectItemTwo {
        left: 128px;
        top: 54px;
        .line {
          height: 36px;
        }
      }
      .selectItemThree {
        left: 170px;
        top: 27px;
        .line {
          height: 52px;
        }
      }
      .selectItemFour {
        left: 224px;
        top: 2px;
        .line {
          height: 78px;
        }
      }
      .selectItemFive {
        left: 270px;
        top: 54px;
        .line {
          height: 36px;
        }
      }
      .selectItemSix {
        left: 308px;
        top: 82px;
        .line {
          height: 40px;
        }
      }
    }
    .middleBottom {
      display: flex;
      .selectItemOne {
        left: 92px;
        bottom: 100px;
        .line {
          height: 30px;
        }
      }
      .selectItemTwo {
        left: 128px;
        bottom: 74px;
        .line {
          height: 36px;
        }
      }
      .selectItemThree {
        left: 170px;
        bottom: 47px;
        .line {
          height: 52px;
        }
      }
      .selectItemFour {
        left: 224px;
        bottom: 22px;
        .line {
          height: 78px;
        }
      }
      .selectItemFive {
        left: 266px;
        bottom: 74px;
        .line {
          height: 36px;
        }
      }
      .selectItemSix {
        left: 300px;
        bottom: 100px;
        .line {
          height: 30px;
        }
      }
    }
    .middleText {
      height: 20px;
      text-align: center;
      line-height: 20px;
    }
  }
  .rightBox {
    @extend .leftBox;
    left: -134px;
  }
  .selectItem {
    display: flex;
    align-items: center;
    position: absolute;
    .displaySelect {
      height: 24px;
      line-height: 24px;
      text-align: center;
      width: 80px;
      border: 1px solid #d9d9d9;
    }
    .line {
      height: 1px;
      width: 30px;
      background: #999;
    }
    .point {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: #999;
    }
  }
  .selectItemColumn {
    @extend .selectItem;
    flex-direction: column;
    .line {
      height: 40px;
      width: 1px;
    }
  }
}

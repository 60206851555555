.carArchive {
  min-height: 100%;
  .searchBox {
    :global {
      .ant-btn:not(:first-child) {
        margin-left: 12px;
      }
    }
  }
  .carInfoBox {
    padding: 12px;
    background: #eee;
    .carInfoTitle {
      font-size: 12px;
      color: #999;
    }
    .carInfoContent {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
    }
  }
  .uploadBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid #eee;
    overflow: hidden;
    .thingBox {
      flex-grow: 1;
      overflow-y: auto;
      .contractItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        border-bottom: 1px solid #eee;
        .contractName {
          word-break: break-all;
        }
        .contractIcon {
          flex-shrink: 0;
        }
      }
    }
    .emptyBox {
      flex-grow: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #eee;
    }
    .uploadButton {
      flex-shrink: 0;
      text-align: right;
      :global {
        .ant-upload-list {
          display: none;
        }
      }
    }
  }
}

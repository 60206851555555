.dataBox {
  background-image: url(./1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  .dataBoxLittleTitle{
    margin-left: 20px;
    padding-left: 10px;
    height: 27px;
    font-size: 15px;
    border-left: 3px solid rgb(0,246,255);
    border-bottom: 1px solid rgb(0,246,255);
    border-image: linear-gradient(to right, rgb(0,246,255), rgb(27,0,71)) 10;
  }
  .dataBoxTitle{
    background-image: url(./2.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    height: 70px;
    width: 100%;
    font-size: 28px;
    font-weight: 800;
    line-height: 55px;
    text-align: center;
  }
  .dataBoxContent{
    color: white;
    height: calc(800px - 70px);
    display: flex;
    .dataBoxContentLeft{
      width: 25%;
      .leftChartOne{
        width: 100%;
      }
      .dataBoxContentLeftItemTitle{
        margin-left: 20px;
        height: 38px;
        font-size: 12px;
        display: flex;
        line-height: 38px;
        text-align: center;
      }
      .dataBoxContentLeftList{
        overflow: hidden;
        margin-left: 20px;
        height: 266px;
        .dataBoxContentLeftNone{
          height: 38px;
          font-size: 12px;
          text-align: center;
          line-height: 38px;
        }
        .dataBoxContentLeftItem{
          height: 38px;
          font-size: 12px;
          display: flex;
          line-height: 38px;
          text-align: center;
          .dataBoxContentLeftItemLeft{
            width: 25%;
          }
          .dataBoxContentLeftItemMiddle{
            width: 25%;
            color:rgb(194,112,43);
            font-size:14px
          }
          .dataBoxContentLeftItemRight{
            width: 50%;
          }
        }
      }
    }
    .dataBoxContentMiddle{
      width: 50%;
      .dataBoxContentMiddleTitle{
        height: 70px;
        display: flex;
        justify-content:space-evenly;
        font-size: 17px;
        p{
          color: rgb(255,214,0);
          font-size: 30px;
        }
      }
      .mapBox {
        height: 400px;
        width: 100%;
      }
    }
    .dataBoxContentRight{
      width: 25%;
      .rightChartOne{
        width: 100%;
      }
      .rightChartTwo{
        width: 100%;
      }
      .rightChartThree{
        width: 100%;
      }
    }
  }
    
}
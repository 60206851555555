.carLocationQuery {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  .exportButtonBox {
    padding: 12px;
    .tips {
      font-weight: bold;
    }
    :global {
      .ant-upload-list {
        display: none;
      }
      .ant-btn {
        margin-left: 12px;
      }
    }
  }
  .carLocationMap {
    height: 100%;
  }
}

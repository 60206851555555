.videoBox {
  overflow: auto;
  .videoForm {
    flex-shrink: 0;
  }
  .videoPlay {
    height: 100%;
    video {
      display: block;
      width: 100%;
      max-height: 100%;
      max-width: 100%;
    }
  }
}
.resourceTable {
  :global {
    .ant-tabs-content-holder {
      background: #fff !important;
      .ant-tabs-tabpane {
        padding: 0 !important;
        overflow-y: hidden !important;
      }
    }
  }
}

.commentTitle {
  font-weight: bold;
  margin: 8px 0;
}
.commentBox {
  border: 1px solid #eee;
  .commentItem {
    padding: 4px;
    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }
  }
}
@import "@/styles/common.scss";
.alarmMonitor {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: 4px;
  border-top: 2px solid $backColor;
  .alarmTabs {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .alarmTabItemBox {
      display: flex;
    }
    .alarmTabItem {
      height: 36px;
      padding: 8px 16px 0;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
      &:hover {
        color: $primaryColor;
        border-bottom: 2px solid $primaryColor;
      }
    }
    .activeAlarmTabItem {
      @extend .alarmTabItem;
      color: $primaryColor;
      border-bottom: 2px solid $primaryColor;
    }
    .macthAlarmTabItem {
      @extend .alarmTabItem;
      color: #000;
      background: red;
    }
    .macthActiveAlarmTabItem {
      @extend .alarmTabItem;
      color: #000;
      background: red;
      border-bottom: 2px solid $primaryColor;
    }
    .iconBox {
      display: flex;
      :global {
        .anticon {
          cursor: pointer;
          margin-right: 8px;
          font-size: 16px;
        }
      }
    }
  }
}
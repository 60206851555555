.carGroupMoveBox {
  height: 100%;
  display: flex;
  justify-content: space-between;
  .carTree {
    flex-shrink: 0;
    width: 45%;
    background-color: #fff;
  }
  .moveButtonBox {
    margin: auto 12px;
  }
  .rightPart {
    display: flex;
    flex-direction: column;
    flex: auto;
    width: 45%;
    padding: 12px;
    background-color: #fff;
    .carBoxTitle {
      line-height: 22px;
      margin-bottom: 8px;
    }
    .carBox {
      overflow: auto;
      border: 1px solid #d9d9d9;
      .carItem {
        padding: 8px 16px;
        &:not(:last-child) {
          border-bottom: 1px solid #f0f0f0;
        }
      }
    }
  }
}
.trackPlaybackBox {
  min-height: 100%;
  display: flex;
  .carTree {
    flex-shrink: 0;
    width: 250px;
    background-color: #fff;
  }
  .monitoring {
    flex: auto;
    min-width: 0;
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    background-color: #fff;
    .bysk-p-layout-view byskplayer-layout-wrapper{
      display: none;
    }
    .monitoringTitle {
      padding: 6px;
      .monitoringButton {
        margin-left: 20px;
      }
    }
    .monitoringContent {
      height: 100%;
      position: relative;
      video{
        border: 0.5px solid white;
        background-color: RGB(1,2,2);
        height: calc(50%);
        display: block;
        float: left;
      }
      .text{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
}
@import "@/styles/common.scss";
.statisticsCard {
  width: 100%;
  background: #fff;
  .title {
    border-bottom: 1px solid $backColor;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    .titleContent {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    :global {
      .ant-tag {
        margin: 0;
      }
    }
  }
  .count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    color: #666;
    font-size: 1.6vw;
  }
}

.operationGroupManage {
  height: 100%;
  display: flex;
  justify-content: space-between;
  .leftPart {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 250px;
    background-color: #fff;
    .buttonBox {
      text-align: right;
      border-bottom: 1px solid #eee;
      flex-shrink: 0;
      padding: 16px;
    }
    .groupBox {
      padding: 16px;
      flex-grow: 1;
      overflow: auto;
      .groupItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .rightPart {
    display: flex;
    flex-direction: column;
    flex: auto;
    min-width: 0;
    background-color: #fff;
    margin-left: 16px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border-bottom: 1px solid #eee;
      flex-shrink: 0;
      font-weight: bold;
    }
    .treeBox {
      padding: 16px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .searchBox {
        flex-shrink: 0;
        margin-bottom: 12px;
      }
      .tree {
        flex-grow: 1;
        overflow: auto;
      }
    }
  }
  .placeholderBox {
    margin-left: 16px;
    background: #fff;
    flex: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
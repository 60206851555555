.toggleBox {
  position: fixed;
  top: 120px;
  background: #1890ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4px;
  width: 25px;
  color: #fff;
  transition: right 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  cursor: pointer;
  z-index: 1001;
  .icon {
    font-size: 18px;
  }
  .text {
    margin-top: 4px;
    border-top: 1px solid #cbc9c9;
    padding: 12px 0;
    font-size: 14px;
    font-weight: 500;
    writing-mode: vertical-lr;
  }
  .count {
    background: red;
    line-height: 22px;
    padding: 0 4px;
    text-align: center;
    border-radius: 4px;
    color: #fff;
    position: absolute;
    top: 0;
    right: 18px;
    min-width: 20px;
  }
}
.pushBox {
  top: 120px;
  bottom: 70px;
  height: calc(100vh - 190px);
  .alarmBox {
    height: 100%;
    overflow: auto;
    .alarmItem {
      border-bottom: 1px solid #e2dbdb;
      margin-bottom: 16px;
      padding-bottom: 16px;
      .title {
        font-size: 16px;
        font-weight: 600;
      }
      .time {
        color: #999;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      :global {
        .ant-btn {
          visibility: hidden;
        }
      }
      &:hover {
        :global {
          .ant-btn {
            visibility: visible;
          }
        }
      }
    }
    .clear {
      position: absolute;
      right: 48px;
      bottom: 60px;
    }
  }
}
.fleetRisk {
  min-height: 100%;
  .searchBox {
    margin-top: 30px;
    :global {
      .ant-btn:not(:first-child) {
        margin-left: 12px;
      }
    }
  }
}
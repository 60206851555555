html, body {
  margin: 0;
  padding: 0;
}
audio {
  height: 24px;
  width: 260px;
  display: block;
}
.BMap_bubble_title {
  line-height: 16px;
}
.BMap_bubble_content {
  font-size: 12px;
}
/* 浅黄背景的单元格 */
.minorWarninmgTableCell {
  background: #ffff00;
}
.ant-table-cell-row-hover.minorWarninmgTableCell {
  background: #ffff00 !important;
}
.ant-table-row-selected .minorWarninmgTableCell {
  background: #ffff00 !important;
}

/* 黄色背景的单元格 */
.warningTableCell {
  background: #faad14;
}
.ant-table-cell-row-hover.warningTableCell {
  background: #faad14 !important;
}
.ant-table-row-selected .warningTableCell {
  background: #faad14 !important;
}
/* 红色背景的单元格 */
.dangerTableCell {
  background: #ff4d4f;
}
.ant-table-cell-row-hover.dangerTableCell {
  background: #ff4d4f !important;
}
.ant-table-row-selected .dangerTableCell {
  background: #ff4d4f !important;
}
/* 蓝色背景的单元格 */
.processTableCell {
  background: #1890ff;
}
.ant-table-cell-row-hover.processTableCell {
  background: #1890ff !important;
}
.ant-table-row-selected .processTableCell {
  background: #1890ff !important;
}
/* 绿色背景的单元格 */
.successTableCell {
  background: #52c41a;
}
.ant-table-cell-row-hover.successTableCell {
  background: #52c41a !important;
}
.ant-table-row-selected .successTableCell {
  background: #52c41a !important;
}
/* 紫色背景的单元格 */
.correctTableCell {
  background: #531dab;
}
.ant-table-cell-row-hover.correctTableCell {
  background: #531dab !important;
}
.ant-table-row-selected .correctTableCell {
  background: #531dab !important;
}
.warningText {
  color: #faad14;
}
.dangerText {
  color: #ff4d4f;
}
.processText {
  color: #1890ff;
}
.successText {
  color: #52c41a;
}
.boldText {
  font-weight: bold;
}
.polylinePoint {
  width: 12px;
  height: 12px;
  background: #BB2299;
  border-radius: 6px;
}
.polylineStartEndPoint {
  background: #BB2299;
  color: #FFFFFF;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  text-align: center;
  line-height: 24px;
  font-size: 10px;
}
.disabledSwitch.ant-switch {
  background-color: #1890ff;
}
.disabledSwitch.ant-switch-checked {
  background-color: red;
}
#webpack-dev-server-client-overlay {
  display: none;
}
.mapAlarmLable {
  min-width: 150px;
}

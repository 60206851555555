.analysisMap {
  position: relative;
  height: 400px;
  margin-bottom: 12px;
  .map {
    height: 100%;
  }
  .sourceForm {
    z-index: 100;
    padding: 12px 0 12px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    :global {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
  .alarmCheckbox {
    z-index: 100;
    padding: 12px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #fff;
  }
}
.tableForm {
  margin-top: 12px;
}
.tableFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .desc {
    position: absolute;
    top: 6px;
    left: 0;
  }
}

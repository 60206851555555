.photoBigTitle{
  margin-top: 10px;
  margin-bottom: 10px;
}

.photoItem{
  width: 100px;
  height: 110px;
  font-size: 12px;
  color: #9C9C9C;
  border: #9C9C9C 1px dashed;
  border-radius: 2px;
  margin-right: 4px;
  .photoItemTitle{
    height: 40px;
  }
  .photoItemPhoto{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.reportBox {
  min-height: 100%;
  .searchBox {
    :global {
      .ant-btn {
        &:not(:first-child) {
          margin-left: 12px;
        }
      }
    }
  }
  .analysisMap {
    height: 400px;
    width: 100%;
  }
  .innerTitle {
    font-size: 14px;
    font-weight: bold;
    margin: 12px 0;
  }
  .tableOperateColumn {
    :global {
      .ant-btn-link {
        padding: 0;
        border: 0;
        height: auto;
      }
    }
  }
}

.loginBg {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #0c3168 url('../../assets/image/loginBg.jpg') center/cover fixed;
  .title {
    font-size: 24px;
    color: #fff;
    text-align: center;
    letter-spacing: 3px;
  }
  .icp{
    margin-bottom: -190px;
    margin-top: 180px;
    float: left;
    color: black;
    a {
      color: #ffd04a; /* 颜色为黑色 */
      text-decoration: none; /* 取消下划线 */
      font-size: 16px;
    }
  }
  .loginBox {
    display: flex;
    max-width: 100vw;
    .logo {
      background-color: #fff;
      padding: 0 35px;
      display: flex;
      align-items: center;
      border-radius: 4px 0 0 4px;
      img {
        width: 60px;
      }
    }
    .loginForm {
      background-color: #074474;
      width: 350px;
      padding: 70px 24px;
      border-radius: 0 4px 4px 0;

      .desc {
        margin-top: 5px;
        font-size: 14px;
        text-align: center;
        color: #bbb9b9;
        letter-spacing: 1px;
      }
   
      :global {
        .ant-form-item-label {
          label {
            color: #bbb9b9;
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
  .dingdingLogin {
    background-color: #074474;
    height: 100%;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
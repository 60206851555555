.uploadImageVideo{
  :global {
    .ant-upload-list {
      display: none;
    }
  }
  .uploadCard {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    width: 200px;
    height: 40px;
  }
  .previewBox {
    display: flex;
    flex-wrap: wrap;
    margin-left: -4px;
    .previewItem {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      width: 100%;
      height: 40px;
      margin-left: 4px;
      margin-bottom: 4px;
      img {
        width: 100%;
        height: 100%;
      }
      :global {
        .anticon {
          color: #d9d9d9;
          font-size: 48px;
        }
      }
      .deleteIcon {
        position: absolute;
        top: 4px;
        right: 4px;
        color: red;
        font-size: 16px;;
      }
    }
  }
}
.trackPlaybackBox {
  min-height: 100%;
  max-height: 100%;
  display: flex;
  .carTree {
    flex-shrink: 0;
    width: 250px;
    background-color: #fff;
  }
  .box{
    display: flex;
  }
.myCustomIcon{
  margin-top: 4px;
  font-size: 20px;
  margin-left: 10px;
  color:rgba(64, 169, 255,.8);
}
  .playbackBox {
    flex: auto;
    min-width: 0;
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    background-color: #fff;
    .mapTitle {
      padding: 12px;
    }
    .switchTitle{
      margin-left: 20px;
    }
    .mapBox {
      position: relative;
      height: 100%;
      min-height: 500px;
      width: 100%;
      .map {
        height: 100%;
      }
      .textIcon {
        background: #BB2299;
        color: #FFFFFF;
        width: 24px;
        height: 24px;
        border-radius: 6px;
        text-align: center;
        line-height: 24px;
        font-size: 10px;
      }
      .gpsPoint { 
        width: 12px;
        height: 12px;
        background: #BB2299;
        border-radius: 6px;
      }
      .gpsPointLarge { 
        width: 24px;
        height: 24px;
        background: #552299;
        //background: #BB2299;
        border-radius: 12px;
      }
      .alarmLable {
        padding: 8px;
        background: #fff;
        border: 1px solid red;
        white-space: nowrap;
        font-size: 12px;
      }
      .alarmCheckbox {
        z-index: 100;
        padding: 12px;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: #fff;
      }
    }
    .buttonBox {
      :global {
        .ant-btn {
          margin-left: 12px;
        }
      }
    }
    .tipsBox {
      min-height: 60px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .tableBox {
      padding: 16px;
      .tableOperateColumn {
        :global {
          .ant-btn-link {
            padding: 0;
            border: 0;
            height: auto;
            &:not(:first-child) {
              margin-left: 4px;
            }
          }
        }
      }
    }
  }
}
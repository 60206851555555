.carImport {
  height: 100%;
  background-color: #fff;
  .title {
    padding: 12px;
    font-size: 14px;
    border-bottom: 1px solid #eee;
  }
  .content {
    padding: 12px;
    .tips {
      background: #eee;
      padding: 16px;
      border-left: 4px solid #1890ff;
      font-size: 14px;
      .desc {
        font-weight: bold;
      }
      .step {
        padding-left: 16px;
      }
    }
    :global {
      .ant-btn.ant-btn-block {
        margin-top: 12px;
      }
    }
  }
}
.importModal {
  height: 240px;
  .selectBox {
    display: flex;
    .label {
      line-height: 32px;
    }
  }
  :global {
    .ant-upload {
      width: 100%;
    }
    .ant-upload-list {
      display: none;
    }
  }
}
@import "@/styles/common.scss";
.crossMark {
  height: 100%;
  display: flex;
  justify-content: space-between;
  .leftPart {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 250px;
    background-color: #fff;
    .buttonBox {
      text-align: right;
      border-bottom: 1px solid $backColor;
      flex-shrink: 0;
      padding: 16px;
    }
    .roadBox {
      padding: 16px;
      flex-grow: 1;
      overflow: auto;
      .roadItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .rightPart {
    display: flex;
    flex-direction: column;
    flex: auto;
    min-width: 0;
    background-color: #fff;
    margin-left: 8px;
    .title {
      padding: 16px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      :global {
        .ant-btn {
          &:not(:first-child) {
            margin-left: 8px;
          }
        }
      }
    }
    .mapBox {
      padding: 16px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }
}

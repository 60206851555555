@import "@/styles/common.scss";
.signalItemBox {
  display: flex;
  align-items: center;
  .signalItemForm {
    border: 1px solid $backColor;
    padding: 12px;
    margin-bottom: 12px;
    flex: auto;
  }
  .deleteIcon {
    flex-shrink: 0;
    margin-left: 4px;
    cursor: pointer;
    :global {
      .anticon {
        font-size: 18px;
        color: red;
      }
    }
  }
}

@import "@/styles/common.scss";
.carTree {
  padding: 8px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-width: 200px;
  max-height: 100vh;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    padding-bottom: 4px;
    .filterIcon {
      cursor: pointer;
      margin-left: 4px;
      &:hover {
        color: $primaryColor;
      }
    }
  }
  .tree {
    padding-top: 8px;
    height: 100%;
    overflow-y: auto;
    :global {
      .ant-tree-node-content-wrapper {
        white-space: nowrap;
      }
    }
  }
}

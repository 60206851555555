.firstRow {
  video {
    height: 200px;
  }
}
.secondRow {
  margin-top: 8px;
  .alarmDetailMap {
    height: 200px;
  }
}

@import "@/styles/common.scss";
.realTimeMonitor {
  display: flex;
  height: 100%;
  background: #fff;
  .carTreeWrapper {
    flex-shrink: 0;
    border-right: 2px solid $backColor;
  }
  .monitorContent {
    margin-left: 4px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    .videoMapMonitor {
      display: flex;
      flex-grow: 1;
      .videoMonitorWrapper {
        margin-right: 4px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        .switchVideoLayout {
          display: flex;
          justify-content: flex-end;
          .blockItem {
            height: 20px;
            width: 20px;
            padding-left: 4px;
            box-sizing: content-box;
            cursor: pointer;
            .blockChildrenItem {
              width: 100%;
              height: 100%;
              background: #999;
            }
            &:hover {
              .blockChildrenItem {
                background: $primaryColor;
              }
            }
          }
        }
        .videoMonitor {
          width: 100%;
          flex-grow: 1;
        }
      }
    }
  }
}
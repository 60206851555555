.operateSensorConfig {
  display: flex;
  .formBox {
    margin-right: 60px;
    padding-right: 60px;
    border-right: 1px solid #999;
    min-width: 500px;
  }
  .imageBox {
    flex-shrink: 0;
  }
}

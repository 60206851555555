.rechargeBox{

    .rechargeBoxRow{
        display: flex;
        justify-content:space-around;
    }
    .rechargeBoxCol{
        height: 140px;
        width: 450px;
        border: 1px solid #ccc;
        margin: 10px 10px 0 10px;
    }
    .rechargeAmount{
        font-size: 26px;
        // font-weight: bold;
        .rechargeAmountButton{
            margin:0 20px 0  30px;
        }
        .rechargeAmountSpan{
           font-size: 12px;
           color: #666;
           font-weight: normal;
        }
    }
}
@import "@/styles/common.scss";
.type,
.time {
  width: 25%;
}
.operate {
  width: 20%;
}
.speed,
.evalute {
  width: 15%;
}
.headerBlock {
  flex-shrink: 0;
  display: flex;
  .leftHeader {
    width: calc(50% - 8px);
    background: #91d5ff;
  }
  .rightHeader {
    width: calc(50% - 8px);
    background: #ffd591;
  }
  .scrollHeader {
    width: 16px;
    background: #ffd591;
  }
  .headerGroupTitle {
    text-align: center;
    line-height: 40px;
  }
  .headerTitle {
    display: flex;
    .title {
      line-height: 24px;
      padding: 8px;
      flex-shrink: 0;
    }
  }
}
.contentBlock {
  flex-grow: 1;
  overflow-y: auto;
  .alarmIntervalBlock {
    border-bottom: 1px solid #d8d8d8;
    display: flex;
    .leftPart {
      width: 50%;
    }
    .rightPart {
      width: 50%;
    }
    .tableRow {
      display: flex;
      align-items: center;
      background: #fafafa;
      .tableCell {
        line-height: 24px;
        padding: 8px;
        flex-shrink: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        :global {
          .ant-switch {
            background-image: none;
            background-color: #ff4d4f;
          }
          .ant-switch-checked {
            background-color: #52c41a;
          }
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid #f0f0f0;
      }
    }
  }
}
.formDeviceTitle {
  color: #989898;
}
.formDeviceBlock {
  border: 1px solid $backColor;
}

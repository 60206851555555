.carLocation {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  .topBox {
    padding: 12px 12px 0;
    .tips {
      font-weight: bold;
      margin-bottom: 8px;
    }
    :global {
      .ant-form-item {
        margin-bottom: 12px;
      }
    }
    .searchButtonBox {
      :global {
        .ant-btn-default {
          margin-left: 8px;
        }
      }
    }
  }
  .carLocationMap {
    height: 100%;
  }
}

.recordDrawer {
  :global {
    .ant-drawer-content-wrapper {
      max-width: 100vw;
    }
    .ant-timeline-item {
      margin-top: 4px;
    }
  }
}
.threeButton{
  display: flex;
  div{
    width: 90px;
    margin: 20px 20px 0 0;
  }
}
.imgBox {
  display: flex;
  flex-wrap: wrap;
  .imgItem {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    margin-top: 4px;
    padding: 4px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
    :global {
      .anticon {
        color: #d9d9d9;
      }
    }
  }
  .smallImgItem {
    @extend .imgItem;
    width: 60px;
    height: 60px;
    :global {
      .anticon {
        font-size: 36px;
      }
    }
  }
  .middleImgItem {
    @extend .imgItem;
    width: 100px;
    height: 100px;
    :global {
      .anticon {
        font-size: 64px;
      }
    }
  }
  .largeImgItem {
    @extend .imgItem;
    width: 120px;
    height: 120px;
    :global {
      .anticon {
        font-size: 80px;
      }
    }
  }
}

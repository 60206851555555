.resizeHandle {
  position: absolute;
  z-index: 1;
}
.horizontalHandle {
  @extend .resizeHandle;
  height: 10px;
  width: 100%;
  left: 0;
  cursor: ns-resize;
}
.verticalHandle {
  @extend .resizeHandle;
  height: 100%;
  width: 10px;
  top: 0;
  cursor: ew-resize;
}
.topHandle {
  @extend .horizontalHandle;
  top: -5px;
}
.rightHandle {
  @extend .verticalHandle;
  right: -5px;
}
.bottomHandle {
  @extend .horizontalHandle;
  bottom: -5px;
}
.leftHandle {
  @extend .verticalHandle;
  left: -5px;
}
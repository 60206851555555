@import "@/styles/common.scss";
.commonTableBox {
  display: flex;
  flex-direction: column;
  overflow: auto;
  .filterColumn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fafafa;
    flex-shrink: 0;
    padding: 12px;
    .rightAction {
      :global {
        .anticon {
          font-size: 16px;
          cursor: pointer;
          margin-left: 8px;
          &:hover {
            color: #1677ff;
          }
        }
      }
    }
  }
  .tableWrapper {
    flex-grow: 1;
    overflow: hidden;
    :global {
      .ant-spin-nested-loading {
        height: 100%;
        .ant-spin-container {
          height: 100%;
          display: flex;
          flex-direction: column;
          .ant-table {
            flex-grow: 1;
            overflow: auto;
            .ant-table-container {
              max-height: 100%;
              display: flex;
              flex-direction: column;
              .ant-table-header {
                flex-shrink: 0;
              }
            }
          }
        }
      }
      .ant-table-pagination.ant-pagination {
        margin: 16px 0 0 0;
      }
    }
  }
  :global {
    .react-resizable {
      position: relative;
      background-clip: padding-box;
    }
    .ant-pagination-options-quick-jumper {
      display: inline-flex;
      align-items: center;
    }
  }
  .reactResizableHandle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: ew-resize;
  }
}
.filterColumnCheckBox {
  border: 1px solid $backColor;
  padding: 8px;
  background: #fff;
  max-height: 400px;
  overflow-y: auto;
  :global {
    .ant-checkbox-group-item {
      display: flex;
      margin-top: 4px;
    }
  }
}

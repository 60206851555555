.transferTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  font-weight: bold;
}
.transferErrorTitle {
  @extend .transferTitle;
  color: red;
}

.layout {
  height: calc(100vh - 20px);
  flex-direction: column;
  background: #fff;
  .layoutContent {
    flex-grow: 1;
    background: #fff;
    .layoutSider {
      display: flex;
      flex-direction: column;
      :global {
        .ant-layout-sider-children {
          display: flex;
          flex-direction: column;
        }
        .ant-layout-sider-trigger {
          border-top: 1px solid #eee;
        }
      }
      .siderMenu {
        flex-grow: 1;
        overflow-y: auto;
        -ms-overflow-style: none; 
        overflow: -moz-scrollbars-none;
        &::-webkit-scrollbar {
          width: 0 !important
        }
        :global {
          .ant-menu-item {
            width: 100%;
          }
        }
      }
    }
    .mainContentWrapper {
      flex-grow: 1;
      overflow: auto;
      .mainContent {
        height: 100%;
        min-width: 860px;
        overflow-y: auto;
        :global {
          .ant-tabs {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            overflow: auto;
            .ant-tabs-nav {
              margin-bottom: 0;
            }
            .ant-tabs-content-holder {
              background: #f0f2f5;
              flex-grow: 1;
              .ant-tabs-content {
                height: 100%;
              }
              .ant-tabs-tabpane {
                overflow-y: auto;
                padding: 8px;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
.home {
  overflow-x: hidden;
  .chartCard {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 600px;
    background: #fff;
    .title {
      border-bottom: 1px solid #eee;
      font-size: 14px;
      padding: 16px;
    }
    .tag {
      margin: 12px 16px;
    }
    .searchBox {
      margin: 12px 16px;
      .searchButton {
        margin-top: 30px;
      }
      .innerTitle {
        font-size: 18px;
      }
    }
    .chart {
      height: 100%;
      padding: 12px;
    }
  }
}

@import "@/styles/common.scss";
.formTableBox {
  .titleBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .itemTitleBox {
    border: 1px solid $backColor;
    :global {
      .ant-col {
        padding: 8px;
        &:not(:first-child) {
          border-left: 1px solid $backColor;
        }
      }
    }
  }
  .itemContentBox {
    border: 1px solid $backColor;
    &:not(:first-child) {
      border-top: 0;
    }
    :global {
      .ant-btn {
        padding: 0;
        border: 0;
        height: auto;
        &:hover {
          background: transparent;
        }
      }
      .ant-col {
        padding: 4px;
        &:not(:first-child) {
          border-left: 1px solid $backColor;
        }
      }
    }
  }
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

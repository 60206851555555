.rechargeCard {
    width: 90%;
    background: #fff;
    border: 1px solid rgba($color: #dad6d6, $alpha: .8);
    border-radius: 10%;
    &:hover {
      cursor: pointer;
      border: 1px solid #7bb8f0;
      background-color: #c8f8ff;
    }
    .click{
      border: 1px solid #f25f8f;
      background-color: #fee7ee;
    }
    .title {
      border-bottom: 1px solid rgba($color: #eee, $alpha: .8);
      font-size: 14px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 10px;
      :global {
        .ant-tag {
          margin: 0;
        }
      }
    }
    .count {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 12px;
      font-size: 14px;
      font-weight: bold;

      .countNum{
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
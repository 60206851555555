.carDiagnosisTable {
  position: relative;
  .aebTitle {
    position: absolute;
    top: 110px;
    left: 20px;
    font-size: 18px;
    z-index: 1;
  }
  :global {
    .ant-tabs-content-holder {
      background: #fff !important;
      .ant-tabs-tabpane {
        padding: 0 !important;
        overflow-y: hidden !important;
      }
    }
  }
}

.layoutHeader {
  flex-shrink: 0;
  display: flex;
  background: #fff;
  padding: 0;
  .checkBox {
    height: 64px;
    display: flex;
    width: 200px;
    align-items: center;
    flex-wrap: wrap;
    > div {
      line-height: 25px;
      min-width: 100%;
      
    }
  }
  .logoTitle {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
    background-color: #001529;
    width: 200px;
    height: 64px;
    color: #fff;
    font-weight: bold;
    position: relative;
    span{
      position: absolute;
    }
    img { 
      width: 40px;
    }
  }
  .headerContent {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    padding: 0 32px;
    text-align: right;
    .toggleIcon {
      line-height: 64px;
      font-size: 16px;
      &:hover{
        color: #1890ff;
      }
    }
    .quickMenu {
      display: inline-block;
      margin-left: 32px;
      cursor: pointer;
      &:hover {
        color: #1890ff;
      }
    }
    .userName {
      padding: 0 24px;
      cursor: pointer;
      svg {
        margin-right: 4px;
      }
    }
  }
}
.informationText:hover .informationBox{
  display: block;
}

.information{
  padding-left: 30px;
  position: relative;
  cursor:pointer;
  .informationBox{
    display: none;
    z-index: 88;
    position: absolute;
    top:64px;
    right: 0;
    height: 350px;
    width: 300px;
    background-color: white;
    overflow: scroll;
    overflow-x:hidden;
    -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,.06);
    box-shadow: 0 0 10px 2px rgba(0,0,0,.06);
    line-height: initial;
    text-align: initial;
    padding: 10px 0;
    .informationBoxReadAll{
      font-size: 12px;
      color: white;
      background-color: #1890ff;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      text-align: center;
      padding-top: 4px;
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
    .informationBoxItem{
      padding: 2px 3px;
      display: flex;
    }
    .informationBoxCenter{
      width: 180px;
    }
    .informationBoxButton{
      width: 105px;
      display: flex;
      .informationBoxButtonItem{
        width: 35px;
        text-align: center;
        line-height: 32px;
        color:#1890ff;
      }
    }
  }
}
.informationBox::-webkit-scrollbar {
  width: 4px;
}
.informationBox::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0,0,0,0.2);
}
.messageCard::-webkit-scrollbar-track {
  border-radius: 0;
  background: rgba(0,0,0,0.1);
}
.informationBox .informationBoxItem:hover{
  background-color: rgb(240, 240, 240);
}

.informationText{
  display: flex;
  position: relative;
  margin-right: 20px;
  .informationNum{
    position: absolute;
    top: 10px;;
    left: 40px;
    background-color: red;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    line-height: initial;
    text-align: center;
    color: white;
  }
}

.menuBox {
  display: flex;
  background-color: #fff;
  padding: 12px;
  color: rgba(0, 0, 0, 0.85);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  .menuGroup {
    max-height: 320px;
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-right: 12px;
    }
    .menuTitle {
      font-weight: 600;
      flex-shrink: 0;
      line-height: 24px;
    }
    .menuItemGroup {
      overflow-y: auto;
      .menuItem {
        max-width: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 24px;
        cursor: pointer;
        &:hover {
          color:#1890ff;
        }
      }
    }
  }
}

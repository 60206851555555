.tagManageBox {
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  .operateBox {
    display: flex;
    justify-content: right;
    border-bottom: 1px solid #eee;
    padding-bottom: 16px;
    :global {
      .ant-btn:not(:first-child) {
        margin-left: 8px;
      }
    }
  }
  .tagBox {
    flex-grow: 1;
    overflow: auto;
    display: flex;
    justify-content: space-between;
    .sortTree {
      padding-top: 16px;
      flex-shrink: 0;
      width: 250px;
      .treeTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .tagList {
      display: flex;
      flex-direction: column;
      padding-top: 16px;
      flex: auto;
      min-width: 0;
      margin-left: 16px;
    }
  }
}
.dailyChart {
  .legendBox {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-bottom: 4px;
    .legendItem {
      display: flex;
      align-items: center;
      .colorBox {
        width: 16px;
        height: 8px;
        margin: 0 6px;
      }
    }
  }
  .switchDate {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .timeBar {
    height: 40px;
    width: 100%;
    .closeTimeBar {
      cursor: pointer;
      height: 40px;
      background-color: #ff4d4f;
    }
    .onlineTimeBar {
      cursor: pointer;
      height: 40px;
      background-color: #52c41a;
    }
    .offlineTimeBar {
      cursor: pointer;
      height: 40px;
      background-color: #f0f2f5;
    }
  }
  .timeLine {
    margin-top: 8px;
    height: 4px;
    background-color: #f0f2f5;  
  }
  .timeDotBox {
    display: flex;
    align-items: center;
    .timeDot {
      flex-grow: 1;
    }
    .lastTimeDot {
      position: absolute;
      right: 0;
    }
  }
}

.onLineRecordBox {
  height: 100%;
  display: flex;
  .carTree {
    flex-shrink: 0;
    width: 250px;
    background-color: #fff;
  }
  .recordBox {
    margin-left: 8px;
    padding: 24px 12px;
    background-color: #fff;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .tips {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .month {
    overflow: auto;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .monthItemBox {
    width: 25%;
    display: flex;
    justify-content: center;
    .monthItem {
      border: 1px solid #1890ff;
      margin-top: 8px;
      background-color: #fff;
      max-height: 200px;
      .title {
        font-weight: 500;
        text-align: center;
      }
      .week {
        display: flex;
        font-weight: 500;
        .weekItem {
          width: 24px;
          line-height: 24px;
          text-align: center;
        }
      }
      .day {
        display: flex;
        flex-wrap: wrap;
        width: 168px;
        .dayItem {
          width: 24px;
          line-height: 24px;
          text-align: center;
        }
        .dayItemOnline {
          @extend .dayItem;
          background-color: #1890ff;
          color: #fff;
        }
      }
    }
  }
}
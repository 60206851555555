.fixPageContentBox {
  height: 100%;
  overflow: auto;
  :global {
    .ant-card-body {
      overflow-y: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.topTableItem{
  position: relative;
  .topTableItemMain{
    color: #000000D9;
    font-size: 14px;
    position: absolute;
    width: 113px;
    height: 55px;
    top: -27.5px;
    left: -16px;
    overflow: hidden;
  }
  .topTableItemMainThree{
    border: 0.5px solid rgb(240, 240, 240);
    width: 400px;
    transform: translate(-200px,1px)rotate(15deg);
  }
  .topTableItemMainSix{
    border: 0.5px solid rgb(240, 240, 240);
    width: 400px;
    transform: translate(-200px,-1px)rotate(37deg);
  }
  .topTableItemMainC{
    position: absolute;
    bottom: 0;
    left: 3px;
  }
  .topTableItemMainP{
    position: absolute;
    bottom: 0;
    right: 3px;
  }
  .topTableItemMainR{
    position: absolute;
    right: 3px;
    top: 0;
  }
}